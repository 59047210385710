import type { Schema } from "@data-driven-forms/react-form-renderer";
import { validatorTypes } from "@data-driven-forms/react-form-renderer";
import componentTypes from "@data-driven-forms/react-form-renderer/component-types";
import dataTypes from "@data-driven-forms/react-form-renderer/data-types";
import { getDay, isBefore, isValid, parse } from "date-fns";
import {
  getBookingCloseHoursInputValue,
  getBookingCloseMinutesInputValue
} from "helpers/activity";
import { formatDate, getDateFromDateAndTimeInputs } from "helpers/date";
import {
  getDiscountRuleTypeReadableName,
  getIsDiscountRuleTypeCode,
  getIsDiscountRuleTypeMultiAttendee,
  getIsDiscountRuleTypeMultiPurchase
} from "helpers/discount";
import {
  getConfiguredFields,
  getDataArrayReplacedWithTempIds
} from "helpers/form";
import {
  getIsClientPremiumPlanOrHigher,
  getIsClientStandardPlanOrHigher
} from "helpers/plan-level";
import { getCanSubscriptionPlanBeAddedToNewTicket } from "helpers/subscription-plan";
import { FormTemplateType } from "types/form";
import type { Activity, AddOn, Ticket } from "types/model/activity";
import { TicketType } from "types/model/activity";
import type {
  ActivityGroup,
  ActivityGroupFormData,
  ActivityGroupSalesData,
  ActivityGroupWithPastActivityIds
} from "types/model/activity-group";
import {
  AgeRestrictionCalculationCriteria,
  AgeRestrictionHandling,
  RepeatEndOption,
  RepeatOption,
  WaitlistType
} from "types/model/activity-group";
import type { CancellationPolicy } from "types/model/cancellation";
import type { Client } from "types/model/client";
import type { DiscountRule } from "types/model/discount-rule";
import {
  DiscountCodeUsageType,
  DiscountRuleType
} from "types/model/discount-rule";
import type { Field, Field as FieldModel } from "types/model/field";
import { FieldType } from "types/model/field";
import type { FormValues } from "types/model/form";
import type { PaymentMethodInstance } from "types/model/payment";
import type { SessionPass } from "types/model/session-pass";
import type { SubscriptionPlan } from "types/model/subscription-plan";
import type { Venue } from "types/model/venue";

interface GenerateActivityGroupFormSchemaData {
  fieldsData: FieldModel[];
  venuesData: Venue[];
  discountRulesData: DiscountRule[];
  sessionPassesData: SessionPass[];
  subscriptionPlansData: SubscriptionPlan[];
  cancellationPoliciesData: CancellationPolicy[];
  isCloning: boolean;
  enabledPaymentMethodsData: PaymentMethodInstance[];
  client: Client;
  activityGroup?: ActivityGroup;
  activityGroupSalesData?: ActivityGroupSalesData;
  activityGroupSalesIncludingCancelled?: ActivityGroupSalesData;
  attendeeFieldsNotApplicableForAllActivities: FieldModel[];
  hasDobFieldEnabled: boolean;
  isDobFieldRestrictedToSpecificActivities: boolean;
}

export const generateActivityGroupFormSchema = ({
  fieldsData,
  venuesData,
  discountRulesData,
  sessionPassesData,
  subscriptionPlansData,
  cancellationPoliciesData,
  isCloning,
  enabledPaymentMethodsData,
  client,
  activityGroup,
  activityGroupSalesData,
  activityGroupSalesIncludingCancelled,
  attendeeFieldsNotApplicableForAllActivities,
  hasDobFieldEnabled,
  isDobFieldRestrictedToSpecificActivities
}: GenerateActivityGroupFormSchemaData): Schema => {
  const configuredFields = getConfiguredFields({
    fieldsData,
    initialData: activityGroup?.fieldData || [],
    offset: 1,
    arrayField: false,
    venuesData,
    isAdminUse: true,
    client
  });

  const doesClientHaveSessionPasses = sessionPassesData.length > 0;
  const doesClientHaveCancellationPolicies =
    cancellationPoliciesData.length > 0;

  const showWaitlistSettings =
    getIsClientPremiumPlanOrHigher(client) &&
    process.env.NEXT_PUBLIC_FEATURE_WAITLISTS === "true";

  const schema = {
    fields: [
      {
        component: "wizard",
        name: "custom-wizard",
        activityGroupId: activityGroup?._id,
        initialState: activityGroup
          ? {
              maxStepIndex: 3,
              prevSteps: ["information", "sessions", "tickets", "settings"],
              registeredFieldsHistory: {
                // according to docs, only values from registered fields will be submitted
                // however values still seem to be submitted if fields are not registered here 🤷
                information: [
                  ...configuredFields.map(field => field.name),
                  "coverImage",
                  "attachments"
                ],
                sessions: ["datesInstances"],
                tickets: ["tickets", "addOns"],
                settings: [
                  "multiPurchaseDiscountRule",
                  "multiAttendeeDiscountRule",
                  "discountCodes",
                  "attendeeFields",
                  "sessionPass",
                  "restrictPaymentMethods",
                  "paymentMethods",
                  "setBookingOpenDate",
                  "bookingOpenDate",
                  "setBookingClosingTime",
                  "bookingClosingTime",
                  "enabled"
                ]
              }
            }
          : {},
        fields: [
          {
            name: "information",
            title: "Information",
            nextStep: "sessions",
            fields: [
              {
                index: 0, // prevent top padding
                component: "section-header",
                name: "informationHeader",
                title: "Information",
                description: "General information about your activity.",
                customClassName: "pt-0 mt-8"
              },
              ...configuredFields.splice(0, 2),
              {
                index: 3,
                component: "image",
                name: "coverImage",
                label: "Cover image",
                helpText: "Used as a banner on the activity page."
              },
              ...configuredFields,
              {
                index: configuredFields.length + 3,
                component: "attachments",
                name: "attachments",
                label: "Attachments"
              }
            ]
          },
          {
            name: "sessions",
            title: "Sessions",
            nextStep: "tickets",
            fields: [
              {
                index: 0, // prevent top padding
                component: "section-header",
                name: "sessionsHeader",
                title: "Sessions",
                description:
                  "Create a session for each time the activity takes place at.",
                customClassName: "pt-0 mt-8"
              },
              {
                index: configuredFields.length + 7,
                component: "activity-date-instances",
                name: "datesInstances",
                label: "Sessions",
                isNewActivityGroup: !activityGroup || isCloning,
                activityGroupSales: activityGroupSalesData,
                activityGroupSalesIncludingCancelled:
                  activityGroupSalesIncludingCancelled,
                required: true,
                validate: [
                  {
                    type: "required",
                    message: "At least one session should be added."
                  }
                ]
              }
            ]
          },
          {
            name: "tickets",
            title: "Tickets",
            nextStep: "settings",
            fields: [
              {
                index: 0, // prevent top padding
                component: "section-header",
                name: "ticketsHeader",
                title: "Tickets",
                description: "The tickets available for the activity.",
                customClassName: "pt-0 mt-8"
              },
              {
                index: configuredFields.length + 9,
                component: "activity-tickets",
                name: "tickets",
                label: "Tickets",
                subscriptionPlansData,
                isNewActivityGroup: !activityGroup || isCloning,
                activityGroupSales: activityGroupSalesData,
                activityGroupSalesIncludingCancelled:
                  activityGroupSalesIncludingCancelled,
                activities:
                  !isCloning && activityGroup?.activities
                    ? activityGroup.activities
                    : [],
                client
              },
              {
                component: "section-header",
                name: "addOnsHeader",
                title: "Add-ons",
                badgeText: "Optional",
                description:
                  "Create additional items to be purchased for each session (e.g. lunch, extended session)."
                // customClassName: 'pt-0 mt-8',
              },
              {
                index: configuredFields.length + 11,
                component: "activity-addons",
                name: "addOns",
                label: "Add-ons",
                isNewActivityGroup: !activityGroup || isCloning,
                activityGroupSales: activityGroupSalesData,
                activityGroupSalesIncludingCancelled:
                  activityGroupSalesIncludingCancelled,
                activities:
                  !isCloning && activityGroup?.activities
                    ? activityGroup.activities
                    : []
              }
            ]
          },
          {
            name: "settings",
            title: "Settings",
            fields: [
              ...(getIsClientStandardPlanOrHigher(client)
                ? [
                    {
                      index: 0, // prevent top padding
                      component: "section-header",
                      name: "discountRulesHeader",
                      title: "Discounts",
                      badgeText: "Optional",
                      description:
                        "Assign Multi purchase and Multi attendee discount rules to the activity. Discount codes that are restricted to specific activities can also be assigned.",
                      customClassName: "pt-0 mt-8"
                    },
                    {
                      index: configuredFields.length + 13,
                      arrayField: false,
                      component: "discount-rule-select",
                      discountRuleType: DiscountRuleType.MultiPurchase,
                      client,
                      dataType: dataTypes.STRING,
                      name: "multiPurchaseDiscountRule",
                      label: getDiscountRuleTypeReadableName(
                        DiscountRuleType.MultiPurchase
                      ),
                      options: discountRulesData
                        .filter(discountRule =>
                          getIsDiscountRuleTypeMultiPurchase(discountRule)
                        )
                        .map(discountRule => ({
                          value: discountRule._id,
                          label: `${discountRule.name}${
                            !discountRule.enabled ? " (Disabled)" : ""
                          }`
                        }))
                    },
                    {
                      index: configuredFields.length + 14,
                      arrayField: false,
                      component: "discount-rule-select",
                      discountRuleType: DiscountRuleType.MultiAttendee,
                      client,
                      dataType: dataTypes.STRING,
                      name: "multiAttendeeDiscountRule",
                      label: getDiscountRuleTypeReadableName(
                        DiscountRuleType.MultiAttendee
                      ),
                      options: discountRulesData
                        .filter(discountRule =>
                          getIsDiscountRuleTypeMultiAttendee(discountRule)
                        )
                        .map(discountRule => ({
                          value: discountRule._id,
                          label: `${discountRule.name}${
                            !discountRule.enabled ? " (Disabled)" : ""
                          }`
                        }))
                    },
                    {
                      index: configuredFields.length + 15,
                      arrayField: false,
                      component: "checkbox-multiple",
                      discountRuleType: DiscountRuleType.Code,
                      client,
                      dataType: dataTypes.STRING,
                      name: "discountCodes",
                      label: "Discount codes",
                      helpText:
                        "Only discount codes that are assigned to specific activities are shown here.",
                      options: discountRulesData
                        .filter(
                          discountRule =>
                            getIsDiscountRuleTypeCode(discountRule) &&
                            discountRule.codeUsage ===
                              DiscountCodeUsageType.SelectedActivities
                        )
                        .map(discountRule => ({
                          value: discountRule._id,
                          label: `${discountRule.name} (${discountRule.code})${
                            !discountRule.enabled ? " (Disabled)" : ""
                          }`
                        }))
                    }
                  ]
                : []),
              ...(getIsClientStandardPlanOrHigher(client) &&
              doesClientHaveSessionPasses
                ? [
                    {
                      index: configuredFields.length + 15,
                      component: "section-header",
                      name: "sessionPassesHeader",
                      title: "Session pass",
                      badgeText: "Optional",
                      description:
                        "Assign a Session pass that can be used to purchase sessions for this activity."
                    },
                    {
                      index: configuredFields.length + 16,
                      arrayField: false,
                      component: componentTypes.SELECT,
                      client,
                      name: "sessionPass",
                      label: "Session pass",
                      disabled: sessionPassesData.some(
                        sessionPass => sessionPass.canBeUsedForAllActivityGroups
                      ),
                      options: sessionPassesData.map(sessionPass => ({
                        value: sessionPass._id,
                        label: `${sessionPass.name}${
                          !sessionPass.enabled ? " (Disabled)" : ""
                        }`
                      }))
                    }
                  ]
                : []),
              ...(attendeeFieldsNotApplicableForAllActivities.length > 0
                ? [
                    {
                      index: configuredFields.length + 10,
                      component: "section-header",
                      name: "attendeeFieldsHeader",
                      title: "Attendee fields",
                      badgeText: "Optional",
                      description:
                        "Select additional fields to include on the attendee registration form for this activity. These fields will be displayed alongside the fields that are set to be shown for all activities."
                    },
                    {
                      index: configuredFields.length + 15,
                      arrayField: false,
                      component: "checkbox-multiple",
                      client,
                      dataType: dataTypes.STRING,
                      name: "attendeeFields",
                      label: "Attendee fields",
                      helpText:
                        "Select fields relevant to this activity to ensure a tailored attendee registration process.",
                      options: attendeeFieldsNotApplicableForAllActivities.map(
                        field => ({
                          value: field._id,
                          label: `${field.title}${
                            !field.enabled ? " (Disabled)" : ""
                          }`
                        })
                      )
                    }
                  ]
                : []),
              {
                index: configuredFields.length + 10,
                component: "section-header",
                name: "paymentMethodsHeader",
                title: "Payment methods",
                badgeText: "Optional",
                description:
                  "Restrict the payment methods that can be used when booking this activity."
              },
              {
                index: configuredFields.length + 20,
                arrayField: false,
                component: componentTypes.SWITCH,
                name: "restrictPaymentMethods",
                label: "Payment restriction",
                switchLabel: "Restrict payment methods",
                isRequired: false,
                dataType: dataTypes.BOOLEAN
              },
              {
                index: configuredFields.length + 21,
                arrayField: false,
                component: "checkbox-multiple",
                name: "paymentMethods",
                label: "Allowed payment methods",
                isRequired: true,
                condition: {
                  when: "restrictPaymentMethods",
                  is: true
                },
                options: enabledPaymentMethodsData.map(paymentMethod => ({
                  value: paymentMethod._id,
                  label: paymentMethod.name
                })),
                validate: [
                  {
                    type: "required",
                    message:
                      "If restricting the allowed payment methods, at least one should be selected."
                  }
                ]
              },
              ...(doesClientHaveCancellationPolicies
                ? [
                    {
                      index: configuredFields.length + 15,
                      component: "section-header",
                      name: "userCancellationsHeader",
                      title: "User cancellations",
                      badgeText: "Optional",
                      description:
                        "Assign a Cancellation policy that applies when a user cancels a booking for this activity. Leave blank if you do not wish to allow user cancellations."
                    },
                    {
                      index: configuredFields.length + 16,
                      arrayField: false,
                      component: componentTypes.SELECT,
                      client,
                      name: "cancellationPolicy",
                      label: "Cancellation policy",
                      disabled: cancellationPoliciesData.some(
                        cancellationPolicy =>
                          cancellationPolicy.appliesToAllActivityGroups
                      ),
                      options: cancellationPoliciesData.map(
                        cancellationPolicy => ({
                          value: cancellationPolicy._id,
                          label: `${cancellationPolicy.name}${
                            !cancellationPolicy.enabled ? " (Disabled)" : ""
                          }`
                        })
                      )
                    }
                  ]
                : []),
              {
                index: configuredFields.length + 22,
                component: "section-header",
                name: "bookingOpenDateHeader",
                title: "Booking opening and closing",
                badgeText: "Optional",
                description:
                  "Set a date and time when the activity will be available for booking and a time before each session when bookings will close. If you do not set a closing time, a session will remain open for booking until the end of the day it takes place on."
              },
              {
                index: configuredFields.length + 23,
                arrayField: false,
                component: componentTypes.SWITCH,
                name: "setBookingOpenDate",
                label: "Booking opening",
                switchLabel: "Set booking opening date and time",
                isRequired: false,
                dataType: dataTypes.BOOLEAN
              },
              {
                index: configuredFields.length + 24,
                arrayField: false,
                component: "date-and-time",
                name: "bookingOpenDate",
                label: "Opening date and time",
                isRequired: true,
                condition: {
                  when: "setBookingOpenDate",
                  is: true
                },
                validate: [
                  {
                    type: "required",
                    message:
                      "If restricting when bookings open, a date and time should be specified."
                  },
                  value => {
                    const dateValue = getDateFromDateAndTimeInputs({
                      dateString: value.date,
                      time: value.time,
                      timeZone: client.timeZone
                    });
                    return !dateValue
                      ? "If restricting when bookings open, a valid date and time should be specified."
                      : undefined;
                  }
                ]
              },
              {
                index: configuredFields.length + 25,
                arrayField: false,
                component: componentTypes.SWITCH,
                name: "setBookingClosingTime",
                label: "Booking closing",
                switchLabel: "Set booking closing time",
                isRequired: false,
                dataType: dataTypes.BOOLEAN
              },
              {
                index: configuredFields.length + 26,
                arrayField: false,
                component: "duration",
                name: "bookingClosingTime",
                label: "Closing time before a session starts",
                isRequired: true,
                condition: {
                  when: "setBookingClosingTime",
                  is: true
                },
                validate: [
                  value => {
                    const hoursValue = parseInt(value?.hours, 10);
                    const minutesValue = parseInt(value?.minutes, 10);
                    if (isNaN(hoursValue) && isNaN(minutesValue)) {
                      return "If restricting when bookings close, a valid time should be specified.";
                    } else if (hoursValue > 99) {
                      return "Hours value should not exceed 99.";
                    } else if (minutesValue > 59) {
                      return "Minutes value should not exceed 59.";
                    }
                  }
                ]
              },
              {
                index: configuredFields.length + 27,
                component: "section-header",
                name: "accessRestrictionsHeader",
                title: "Visibility & access",
                badgeText: "Optional",
                description:
                  "Prevent the activity from being displayed on the site's homepage listings. You can also require a password to restrict access to a specific group of users."
              },
              {
                index: configuredFields.length + 30,
                arrayField: false,
                component: componentTypes.SWITCH,
                name: "hideFromHomeListings",
                label: "Visibility",
                switchLabel: "Hide from home page listings",
                isRequired: false,
                dataType: dataTypes.BOOLEAN
              },
              {
                index: configuredFields.length + 28,
                arrayField: false,
                component: componentTypes.SWITCH,
                name: "requireAccessPassword",
                label: "Access",
                switchLabel: "Require a password to access this activity",
                isRequired: false,
                dataType: dataTypes.BOOLEAN
              },
              {
                index: configuredFields.length + 29,
                arrayField: false,
                component: componentTypes.TEXT_FIELD,
                name: "accessPassword",
                label: "Password",
                isRequired: true,
                condition: {
                  when: "requireAccessPassword",
                  is: true
                },
                validate: [
                  { type: validatorTypes.REQUIRED },
                  {
                    type: validatorTypes.PATTERN,
                    pattern: /^[a-zA-Z0-9]*$/,
                    message: "Code can only contain letters and numbers."
                  }
                ]
              },
              ...(hasDobFieldEnabled
                ? [
                    {
                      index: configuredFields.length + 30,
                      component: "section-header",
                      name: "ageRestrictionsHeader",
                      title: "Age restrictions",
                      badgeText: "Optional",
                      description: `Restrict the activity to attendees within a specific age range. Settings only apply when the Attendee Date of Birth field is enabled${
                        isDobFieldRestrictedToSpecificActivities
                          ? " for this activity"
                          : ""
                      }.`
                    },
                    {
                      index: configuredFields.length + 32,
                      arrayField: false,
                      component: "radio-group-with-description-plain",
                      name: "ageRestrictionCriteria",
                      label: "Age restriction criteria",
                      isRequired: true,
                      validate: [
                        {
                          type: "required"
                        }
                      ],
                      options: [
                        {
                          value:
                            AgeRestrictionCalculationCriteria.NoRestriction,
                          label: "No restriction",
                          description:
                            "Choose this option if there are no age restrictions for the activity."
                        },
                        {
                          value:
                            AgeRestrictionCalculationCriteria.AgeOnSessionDate,
                          label: "Restrict based on age on session date",
                          description:
                            "Select this to apply age restrictions based on the attendee's age as of the date the session takes place."
                        },
                        {
                          value: AgeRestrictionCalculationCriteria.BirthDate,
                          label: "Restrict based on Date of Birth",
                          description:
                            "Use this option to set restrictions directly based on the attendee's birthdate."
                        }
                      ]
                    },
                    {
                      index: configuredFields.length + 26,
                      arrayField: false,
                      component: "age-input",
                      name: "ageRestrictionMinAge",
                      label: "Age attendee must be older than",
                      helpText:
                        "Specify the minimum age requirement. For instance, entering '5 Years, 0 Months' means attendees must be over 5 years old to participate.",
                      initialValue: {
                        years: "",
                        months: ""
                      },
                      validate: [validateAgeInput],
                      condition: {
                        when: "ageRestrictionCriteria",
                        is: AgeRestrictionCalculationCriteria.AgeOnSessionDate
                      }
                    },
                    {
                      index: configuredFields.length + 26,
                      arrayField: false,
                      component: "age-input",
                      name: "ageRestrictionMaxAge",
                      label: "Age attendee must be younger than",
                      helpText:
                        "Enter the maximum age limit. For example, '11 Years, 0 Months' allows attendees who are 10 years old or younger, but not 11.",
                      initialValue: {
                        years: "",
                        months: ""
                      },
                      validate: [validateAgeInput],
                      condition: {
                        when: "ageRestrictionCriteria",
                        is: AgeRestrictionCalculationCriteria.AgeOnSessionDate
                      }
                    },
                    {
                      index: 7,
                      arrayField: false,
                      component: componentTypes.DATE_PICKER,
                      name: "ageRestrictionMinDob",
                      label: "Earliest eligible birthdate",
                      helpText:
                        "Attendees must be born on or after this date to be eligible.",
                      condition: {
                        when: "ageRestrictionCriteria",
                        is: AgeRestrictionCalculationCriteria.BirthDate
                      }
                    },
                    {
                      index: 7,
                      arrayField: false,
                      component: componentTypes.DATE_PICKER,
                      name: "ageRestrictionMaxDob",
                      label: "Latest eligible birthdate",
                      helpText:
                        "Attendees must be born on or before this date to be eligible.",
                      condition: {
                        when: "ageRestrictionCriteria",
                        is: AgeRestrictionCalculationCriteria.BirthDate
                      }
                    },
                    {
                      index: configuredFields.length + 33,
                      arrayField: false,
                      component: "radio-group-with-description-plain",
                      name: "ageRestrictionHandling",
                      label: "Handling when not within set age range",
                      isRequired: true,
                      validate: [
                        {
                          type: "required"
                        }
                      ],
                      options: [
                        {
                          value: AgeRestrictionHandling.Block,
                          label: "Block",
                          description: "Prevent the attendee from being booked."
                        },
                        {
                          value: AgeRestrictionHandling.Warn,
                          label: "Display warning",
                          description: "Warn the user but allow the booking."
                        }
                      ],
                      condition: {
                        or: [
                          {
                            when: "ageRestrictionCriteria",
                            is: AgeRestrictionCalculationCriteria.AgeOnSessionDate
                          },
                          {
                            when: "ageRestrictionCriteria",
                            is: AgeRestrictionCalculationCriteria.BirthDate
                          }
                        ]
                      }
                    }
                  ]
                : []),
              {
                index: configuredFields.length + 30,
                component: "section-header",
                name: "bookingConfirmationEmailMsgHeader",
                title: "Confirmation email",
                badgeText: "Optional",
                description:
                  "Include information in the confirmation email when a user makes a booking for this activity."
              },
              {
                index: configuredFields.length + 31,
                arrayField: false,
                component: componentTypes.TEXTAREA,
                name: "bookingConfirmationEmailMsg",
                label: "Message",
                shouldIncludeLinkOptions: true,
                shouldIncludeMediaOptions: false
              },
              ...(showWaitlistSettings
                ? [
                    {
                      index: configuredFields.length + 33,
                      component: "section-header",
                      name: "waitlistHeader",
                      title: "Waitlist settings",
                      description:
                        "Enable a waitlist for the activity to allow users to join when it is fully booked.",
                      condition: {
                        when: "tickets",
                        is: (tickets: Ticket[]) => {
                          return tickets.some(ticket => ticket.enabled);
                        }
                      }
                    },
                    {
                      index: configuredFields.length + 35,
                      arrayField: false,
                      component: "radio-group-with-description-plain",
                      client,
                      isRequired: true,
                      isDisabled: !!activityGroup?.waitlistActiveEntriesType,
                      name: "waitlistType",
                      label: "Waitlist type",
                      validate: [
                        {
                          type: "required",
                          message: "Please select an option."
                        }
                      ],
                      helpText: activityGroup?.waitlistActiveEntriesType
                        ? "Cannot change waitlist type as there are active waitlist entries."
                        : "",
                      resolveProps: (
                        _props: unknown,
                        _field: unknown,
                        formOptions: { getState: () => FormValues }
                      ) => {
                        const values = formOptions.getState().values;
                        const tickets = values?.tickets as Ticket[];
                        const doesActivityHaveAllSessionsTicket = tickets
                          .filter(ticket => ticket.enabled)
                          .some(ticket =>
                            [TicketType.All, TicketType.Subscription].includes(
                              ticket.type
                            )
                          );
                        const waitlistTypeOptions = [
                          {
                            value: "none",
                            label: "None",
                            description:
                              "No waitlist will be available for this activity."
                          },
                          {
                            value: WaitlistType.AllSessions,
                            label: "All sessions",
                            description:
                              "Allows users to join the waitlist for all sessions. You can only enable this option if you have a ticket that allows access to all sessions.",
                            disabled: !doesActivityHaveAllSessionsTicket
                          },
                          {
                            value: WaitlistType.SingleSession,
                            label: "Single session",
                            description:
                              "Allows users to join the waitlist for a single session."
                          }
                        ];

                        return { options: waitlistTypeOptions };
                      }
                    }
                  ]
                : []),
              {
                index: configuredFields.length + 32,
                component: "section-header",
                name: "statusHeader",
                title: "Status",
                description:
                  "By setting the status to Enabled it will be publicly visible and you can take bookings for the activity."
              },
              {
                index: configuredFields.length + 33,
                arrayField: false,
                component: componentTypes.SWITCH,
                name: "enabled",
                label: "Enabled"
              }
            ]
          }
        ]
      }
    ]
  };

  return schema;
};

export type GetActivityGroupFormInitialValuesData = {
  activityGroup: ActivityGroupWithPastActivityIds;
  activityFields: Field[];
  attendeeFields: Field[];
  sessionPasses: SessionPass[];
  cancellationPolicies: CancellationPolicy[];
  client: Client;
  isCloning: boolean;
};

export const getActivityGroupFormInitialValues = ({
  activityGroup,
  activityFields,
  attendeeFields,
  sessionPasses,
  cancellationPolicies,
  client,
  isCloning
}: GetActivityGroupFormInitialValuesData) => {
  const isClientStandardPlanOrHigher = getIsClientStandardPlanOrHigher(client);

  const activityFieldValues = activityFields
    .filter(field => field.enabled)
    .reduce((acc, field) => {
      let initialValue: unknown;
      if (
        [
          FieldType.Text,
          FieldType.Textarea,
          FieldType.Email,
          FieldType.Currency,
          FieldType.Integer,
          FieldType.Number
        ].includes(field.type)
      ) {
        initialValue = activityGroup.fieldData.find(
          fieldDataItem => fieldDataItem?.field?._id === field._id
        )?.value;
      } else if (field.type === FieldType.Date) {
        const dateValue = (initialValue = activityGroup.fieldData.find(
          fieldDataItem => fieldDataItem?.field?._id === field._id
        )?.value);
        if (dateValue) {
          initialValue = formatDate(dateValue, "yyyy-MM-dd", client.timeZone);
        }
      } else if (field.type === FieldType.SelectList) {
        initialValue = activityGroup.fieldData.find(
          fieldDataItem => fieldDataItem?.field?._id === field._id
        )?.valueRef?._id;
      } else if (field.type === FieldType.CheckboxMultiple) {
        initialValue = activityGroup.fieldData
          .find(fieldDataItem => fieldDataItem?.field?._id === field._id)
          ?.valueRefArray.map(item => item._id);
      } else if (field.type === FieldType.CheckboxSingle) {
        initialValue = activityGroup.fieldData.find(
          fieldDataItem => fieldDataItem?.field?._id === field._id
        )?.value;
      } else if (field.type === FieldType.Venue) {
        initialValue = activityGroup.fieldData.find(
          fieldDataItem => fieldDataItem?.field?._id === field._id
        )?.valueRefVenue?._id;
      }

      if (initialValue) {
        acc[field._id] = initialValue;
      }

      return acc;
    }, {});

  const getInitialTicketsValue = (
    activityGroup: ActivityGroup,
    isCloning: boolean
  ): Ticket[] => {
    if (isCloning) {
      const ticketsWithIdsReplacedAndSessionRestrictionsRemoved: Ticket[] =
        getDataArrayReplacedWithTempIds(activityGroup.tickets);

      const initialTicketsValueWhenCloning =
        ticketsWithIdsReplacedAndSessionRestrictionsRemoved.map(ticket => {
          const updatedTicket = { ...ticket };
          if (ticket.type === TicketType.Subscription) {
            const canSubscriptionPlanBeAddedToNewTicket =
              getCanSubscriptionPlanBeAddedToNewTicket(ticket.subscriptionPlan);
            if (!canSubscriptionPlanBeAddedToNewTicket) {
              updatedTicket.subscriptionPlan = null;
            }
          }
          updatedTicket.restrictSessions = false;
          updatedTicket.sessionsCanBeUsedFor = [];
          return updatedTicket;
        });
      return initialTicketsValueWhenCloning;
    }

    return activityGroup.tickets;
  };

  const getInitialAddOnsValue = (
    activityGroup: ActivityGroup,
    isCloning: boolean,
    isClientStandardPlanOrHigher: boolean
  ): AddOn[] => {
    if (!isClientStandardPlanOrHigher && isCloning) return [];
    return isCloning
      ? getDataArrayReplacedWithTempIds(activityGroup.addOns)
      : activityGroup.addOns;
  };

  const getInitialSessionPassValue = (
    activityGroup: ActivityGroup,
    sessionPasses: SessionPass[],
    isCloning: boolean,
    isClientStandardPlanOrHigher: boolean
  ): string => {
    if (!isClientStandardPlanOrHigher && isCloning) return null;
    const initialSessionPassValue =
      sessionPasses.find(
        sessionPass => sessionPass.canBeUsedForAllActivityGroups
      )?._id || activityGroup.sessionPasses[0]?._id;

    return initialSessionPassValue;
  };

  const getInitialCancellationPolicyValue = (
    activityGroup: ActivityGroup,
    cancellationPolicies: CancellationPolicy[]
  ): string => {
    const initialCancellationPolicyValue =
      cancellationPolicies.find(
        cancellationPolicy => cancellationPolicy.appliesToAllActivityGroups
      )?._id || activityGroup.cancellationPolicies[0]?._id;

    return initialCancellationPolicyValue;
  };

  const attendeeFieldsIdsForActivity = attendeeFields
    .filter(
      field =>
        field.appliesToAllActivityGroups === false &&
        field.activityGroups.includes(activityGroup._id)
    )
    .map(field => field._id);

  return {
    // info
    ...activityFieldValues,
    coverImage: activityGroup.coverImage,
    attachments: activityGroup.attachments,

    // sessions
    datesInstances: isCloning ? [] : activityGroup.activities,

    // tickets
    tickets: getInitialTicketsValue(activityGroup, isCloning),
    addOns: getInitialAddOnsValue(
      activityGroup,
      isCloning,
      isClientStandardPlanOrHigher
    ),

    // settings
    multiPurchaseDiscountRule: activityGroup.discountRules?.find(discountRule =>
      getIsDiscountRuleTypeMultiPurchase(discountRule)
    )?._id,
    multiAttendeeDiscountRule: activityGroup.discountRules?.find(discountRule =>
      getIsDiscountRuleTypeMultiAttendee(discountRule)
    )?._id,
    discountCodes: activityGroup.discountRules
      ?.filter(discountRule => getIsDiscountRuleTypeCode(discountRule))
      .map(discountRule => discountRule._id),
    sessionPass: getInitialSessionPassValue(
      activityGroup,
      sessionPasses,
      isCloning,
      isClientStandardPlanOrHigher
    ),
    attendeeFields: attendeeFieldsIdsForActivity,
    restrictPaymentMethods: activityGroup.restrictPaymentMethods,
    cancellationPolicy: getInitialCancellationPolicyValue(
      activityGroup,
      cancellationPolicies
    ),
    paymentMethods: activityGroup.paymentMethods,
    setBookingOpenDate: activityGroup.setBookingOpenDate,
    bookingOpenDate: {
      date: activityGroup.bookingOpenDate
        ? formatDate(
            activityGroup.bookingOpenDate,
            "yyyy-MM-dd",
            client.timeZone
          )
        : "",
      time: activityGroup.bookingOpenDate
        ? formatDate(activityGroup.bookingOpenDate, "HH:mm", client.timeZone)
        : ""
    },
    setBookingClosingTime: activityGroup.setBookingClosingTime,
    bookingClosingTime: {
      hours: getBookingCloseHoursInputValue(activityGroup.bookingClosingTime),
      minutes: getBookingCloseMinutesInputValue(
        activityGroup.bookingClosingTime
      )
    },
    ageRestrictionCriteria:
      activityGroup.ageRestrictions?.criteria ||
      AgeRestrictionCalculationCriteria.NoRestriction,
    ...(activityGroup.ageRestrictions?.criteria ===
      AgeRestrictionCalculationCriteria.AgeOnSessionDate && {
      ageRestrictionMinAge: {
        years: activityGroup.ageRestrictions?.minAge?.years ?? "",
        months: activityGroup.ageRestrictions?.minAge?.months ?? ""
      },
      ageRestrictionMaxAge: {
        years: activityGroup.ageRestrictions?.maxAge?.years ?? "",
        months: activityGroup.ageRestrictions?.maxAge?.months ?? ""
      }
    }),
    ...(activityGroup.ageRestrictions?.criteria ===
      AgeRestrictionCalculationCriteria.BirthDate && {
      ageRestrictionMinDob: activityGroup.ageRestrictions?.minDob
        ? formatDate(
            activityGroup.ageRestrictions.minDob,
            "yyyy-MM-dd",
            client.timeZone
          )
        : "",
      ageRestrictionMaxDob: activityGroup.ageRestrictions?.maxDob
        ? formatDate(
            activityGroup.ageRestrictions.maxDob,
            "yyyy-MM-dd",
            client.timeZone
          )
        : ""
    }),
    ageRestrictionHandling: activityGroup.ageRestrictions?.handling,
    hideFromHomeListings: Boolean(
      activityGroup.accessRestrictions?.hideFromHomeListings
    ),
    requireAccessPassword: Boolean(
      activityGroup.accessRestrictions?.requirePassword
    ),
    accessPassword: activityGroup.accessRestrictions?.password || "",
    bookingConfirmationEmailMsg: activityGroup.bookingConfirmationEmailMsg,
    waitlistType: activityGroup.waitlistType || WaitlistType.None,
    enabled: activityGroup.enabled
  };
};

interface GenerateActivitySessionFormSchemaData {
  formTemplate?: FormTemplateType;
  prefix: string;
  editingSession?: Activity;
  client: Client;
}

export const generateActivitySessionFormSchema = ({
  formTemplate = FormTemplateType.Default,
  prefix,
  editingSession,
  client
}: GenerateActivitySessionFormSchemaData) => {
  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.DATE_PICKER,
      name: `${prefix}.date`,
      label: "Date",
      isRequired: true,
      validate: [{ type: "required" }],
      formTemplate,
      ...(editingSession && {
        initialValue: formatDate(
          editingSession.date.start,
          "yyyy-MM-dd",
          client.timeZone
        )
      })
    },
    {
      index: 1,
      arrayField: false,
      component: componentTypes.TIME_PICKER,
      name: `${prefix}.startTime`,
      label: "Start time",
      isRequired: true,
      validate: [{ type: "required" }],
      formTemplate,
      ...(editingSession && {
        initialValue: formatDate(
          editingSession.date.start,
          "HH:mm",
          client.timeZone
        )
      })
    },
    {
      index: 2,
      arrayField: false,
      component: componentTypes.TIME_PICKER,
      name: `${prefix}.endTime`,
      label: "End time",
      isRequired: true,
      validate: [{ type: "required" }],
      formTemplate,
      ...(editingSession && {
        initialValue: formatDate(
          editingSession.date.end,
          "HH:mm",
          client.timeZone
        )
      })
    },
    {
      index: 3,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: `${prefix}.placeLimit`,
      label: "Place limit",
      inputType: "number",
      isRequired: false,
      formTemplate,
      initialValue: editingSession?.placeLimit,
      validate: [
        value => {
          const valueParsed = parseInt(value, 10);
          return valueParsed < 1
            ? "If specifying a place limit it must be at least 1"
            : undefined;
        }
      ],
      helpText:
        "Limits the number of attendees that can be booked for this session. Leave blank if there is no limit."
    },
    ...(!editingSession
      ? [
          {
            index: 4,
            arrayField: false,
            component: componentTypes.SELECT,
            name: `${prefix}.repeatOption`,
            label: "Repeat",
            emptyOptionLabel: "None",
            isRequired: false,
            formTemplate,
            options: [
              {
                value: RepeatOption.Daily,
                label: "Daily"
              },
              {
                value: RepeatOption.Weekly,
                label: "Weekly"
              }
            ]
          },
          {
            index: 5,
            arrayField: false,
            component: "checkbox-multiple-circles",
            name: `${prefix}.repeatDays`,
            label: "Days to repeat on",
            isRequired: true,
            validate: [{ type: "required" }],
            formTemplate,
            options: [
              {
                value: "1",
                label: "M"
              },
              {
                value: "2",
                label: "T"
              },
              {
                value: "3",
                label: "W"
              },
              {
                value: "4",
                label: "T"
              },
              {
                value: "5",
                label: "F"
              },
              {
                value: "6",
                label: "S"
              },
              {
                value: "0",
                label: "S"
              }
            ],
            condition: {
              when: `${prefix}.repeatOption`,
              is: RepeatOption.Daily
            },
            initialValue: ["1", "2", "3", "4", "5"]
            // TODO: Should initial value always include the day of the week the first session is on?
            // and maybe this should be required
          },
          {
            index: 6,
            arrayField: false,
            component: "radio-group-with-description-plain",
            name: `${prefix}.repeatEndOption`,
            label: "Repeat ends",
            isRequired: true,
            validate: [{ type: "required" }],
            formTemplate,
            options: [
              {
                value: RepeatEndOption.Occurrences,
                label: "After a number of occurrences"
              },
              {
                value: RepeatEndOption.Date,
                label: "On a specific date"
              }
            ],
            condition: {
              when: `${prefix}.repeatOption`,
              isNotEmpty: true
            },
            initialValue: "occurrences"
          },
          {
            index: 7,
            arrayField: false,
            component: componentTypes.TEXT_FIELD,
            name: `${prefix}.repeatOccurrences`,
            label: "Occurrences",
            isRequired: true,
            validate: [{ type: "required" }],
            inputType: "number",
            formTemplate,
            condition: [
              {
                when: `${prefix}.repeatOption`,
                isNotEmpty: true
              },
              {
                when: `${prefix}.repeatEndOption`,
                is: RepeatEndOption.Occurrences
              }
            ]
          },
          {
            index: 7,
            arrayField: false,
            component: componentTypes.DATE_PICKER,
            name: `${prefix}.repeatEndDate`,
            label: "Date repeat ends",
            isRequired: true,
            validate: [{ type: "required" }],
            formTemplate,
            condition: [
              {
                when: `${prefix}.repeatOption`,
                isNotEmpty: true
              },
              {
                when: `${prefix}.repeatEndOption`,
                is: RepeatEndOption.Date
              }
            ]
          }
        ]
      : [])
  ];

  const schema = {
    fields
  };

  return schema;
};

interface ActivityGroupFormErrors {
  activitySessionSubForm?: {
    endTime?: string;
    repeatEndDate?: string;
    repeatDays?: string;
  };
  activityTicketSubForm?: {
    overrideEndTime?: string;
  };
  ageRestrictionCriteria?: string;
  ageRestrictionMaxAge?: string;
  ageRestrictionMaxDob?: string;
}

export const validateActivityGroupForm = (values: FormValues) => {
  const errors: ActivityGroupFormErrors = {};
  if (
    values.activitySessionSubForm?.startTime &&
    values.activitySessionSubForm?.endTime
  ) {
    const parsedStartTime = parse(
      values.activitySessionSubForm.startTime,
      "HH:mm",
      new Date()
    );
    const parsedEndTime = parse(
      values.activitySessionSubForm.endTime,
      "HH:mm",
      new Date()
    );
    if (isBefore(parsedEndTime, parsedStartTime)) {
      errors.activitySessionSubForm = errors.activitySessionSubForm || {};
      errors.activitySessionSubForm.endTime =
        "End time must be after start time";
    }
  }

  if (
    values.activitySessionSubForm?.repeatOption &&
    values.activitySessionSubForm?.repeatEndOption === RepeatEndOption.Date &&
    values.activitySessionSubForm?.date &&
    values.activitySessionSubForm?.repeatEndDate
  ) {
    const parsedSessionDate = new Date(values.activitySessionSubForm.date);
    const parsedRepeatEndDate = new Date(
      values.activitySessionSubForm.repeatEndDate
    );

    if (isBefore(parsedRepeatEndDate, parsedSessionDate)) {
      errors.activitySessionSubForm = errors.activitySessionSubForm || {};
      errors.activitySessionSubForm.repeatEndDate =
        "Date repeat ends cannot be before date of session";
    }
  }

  if (
    values.activitySessionSubForm?.date &&
    values.activitySessionSubForm?.repeatOption === RepeatOption.Daily &&
    values.activitySessionSubForm?.repeatDays
  ) {
    const dayOfTheWeek = getDay(new Date(values.activitySessionSubForm.date));
    const repeatDays = values.activitySessionSubForm.repeatDays.map(
      (day: string) => parseInt(day, 10)
    );

    if (!repeatDays.includes(dayOfTheWeek)) {
      errors.activitySessionSubForm = errors.activitySessionSubForm || {};
      errors.activitySessionSubForm.repeatDays =
        "Days to repeat on must include the day of the week the first session is on";
    }
  }

  if (
    values.activityTicketSubForm?.overrideStartTime &&
    values.activityTicketSubForm?.overrideEndTime
  ) {
    const parsedStartTime = parse(
      values.activityTicketSubForm.overrideStartTime,
      "HH:mm",
      new Date()
    );
    const parsedEndTime = parse(
      values.activityTicketSubForm.overrideEndTime,
      "HH:mm",
      new Date()
    );
    if (isBefore(parsedEndTime, parsedStartTime)) {
      errors.activityTicketSubForm = errors.activityTicketSubForm || {};
      errors.activityTicketSubForm.overrideEndTime =
        "End time must be after start time";
    }
  }

  if (
    values.ageRestrictionCriteria ===
    AgeRestrictionCalculationCriteria.AgeOnSessionDate
  ) {
    const isMinAgeSet =
      values.ageRestrictionMinAge?.years || values.ageRestrictionMinAge?.months;
    const isMaxAgeSet =
      values.ageRestrictionMaxAge?.years || values.ageRestrictionMaxAge?.months;

    if (!isMinAgeSet && !isMaxAgeSet) {
      errors.ageRestrictionCriteria =
        "If restricting based on age on session date, at least one of the age fields below should be completed.";
    } else if (isMinAgeSet && isMaxAgeSet) {
      const minAgeYears = values.ageRestrictionMinAge.years
        ? parseInt(values.ageRestrictionMinAge.years, 10)
        : 0;
      const minAgeMonths = values.ageRestrictionMinAge.months
        ? parseInt(values.ageRestrictionMinAge.months, 10)
        : 0;
      const maxAgeYears = values.ageRestrictionMaxAge.years
        ? parseInt(values.ageRestrictionMaxAge.years, 10)
        : 0;
      const maxAgeMonths = values.ageRestrictionMaxAge.months
        ? parseInt(values.ageRestrictionMaxAge.months, 10)
        : 0;

      // check that max age is greater than max age
      if (
        maxAgeYears < minAgeYears ||
        (maxAgeYears === minAgeYears && maxAgeMonths <= minAgeMonths)
      ) {
        errors.ageRestrictionMaxAge =
          "Age attendee must be younger than must be greater than age they must be younger than.";
      }
    }
  }

  if (
    values.ageRestrictionCriteria ===
    AgeRestrictionCalculationCriteria.BirthDate
  ) {
    if (
      !isValid(new Date(values.ageRestrictionMinDob)) &&
      !isValid(new Date(values.ageRestrictionMaxDob))
    ) {
      errors.ageRestrictionCriteria =
        "If restricting based on Date of Birth, at least one of the date fields below should be completed.";
    } else if (
      isValid(new Date(values.ageRestrictionMinDob)) &&
      isValid(new Date(values.ageRestrictionMaxDob)) &&
      isBefore(
        new Date(values.ageRestrictionMaxDob),
        new Date(values.ageRestrictionMinDob)
      )
    ) {
      errors.ageRestrictionMaxDob =
        "Latest eligible birthdate must be after earliest eligible birthdate.";
    }
  }

  return errors;
};

const validateAgeInput = value => {
  if (value?.years) {
    const yearsValue = parseInt(value?.years, 10);
    if (isNaN(yearsValue)) {
      return "Years value should be a number.";
    } else if (yearsValue < 0) {
      return "Years value cannot be negative.";
    } else if (yearsValue > 99) {
      return "Years value should not exceed 99.";
    }
  }

  if (value?.months) {
    const monthsValue = parseInt(value?.months, 10);
    if (isNaN(monthsValue)) {
      return "Months value should be a number.";
    } else if (monthsValue < 0) {
      return "Months value cannot be negative.";
    } else if (monthsValue > 11) {
      return "Months value should not exceed 11.";
    }
  }
};

export const getActivityGroupFormDataToSubmitWithAgeRestrictionsData = (
  formDataToSubmit: ActivityGroupFormData,
  client: Client
) => {
  if (
    formDataToSubmit.ageRestrictionCriteria ===
      AgeRestrictionCalculationCriteria.AgeOnSessionDate &&
    (formDataToSubmit.ageRestrictionMinAge.years ||
      formDataToSubmit.ageRestrictionMinAge.months)
  ) {
    formDataToSubmit.ageRestrictionMinAge = {
      years: formDataToSubmit.ageRestrictionMinAge.years
        ? parseInt(formDataToSubmit.ageRestrictionMinAge.years as string, 10)
        : 0,
      months: formDataToSubmit.ageRestrictionMinAge.months
        ? parseInt(formDataToSubmit.ageRestrictionMinAge.months as string, 10)
        : 0
    };
  }
  if (
    formDataToSubmit.ageRestrictionCriteria ===
      AgeRestrictionCalculationCriteria.AgeOnSessionDate &&
    (formDataToSubmit.ageRestrictionMaxAge.years ||
      formDataToSubmit.ageRestrictionMaxAge.months)
  ) {
    formDataToSubmit.ageRestrictionMaxAge = {
      years: parseInt(
        formDataToSubmit.ageRestrictionMaxAge.years as string,
        10
      ),
      months: parseInt(
        formDataToSubmit.ageRestrictionMaxAge.months as string,
        10
      )
    };
  }
  if (
    formDataToSubmit.ageRestrictionCriteria ===
      AgeRestrictionCalculationCriteria.BirthDate &&
    formDataToSubmit.ageRestrictionMinDob
  ) {
    formDataToSubmit.ageRestrictionMinDob =
      getDateFromDateAndTimeInputs({
        dateString: formDataToSubmit.ageRestrictionMinDob as string,
        time: "00:00",
        timeZone: client.timeZone
      }) || null;
  }
  if (
    formDataToSubmit.ageRestrictionCriteria ===
      AgeRestrictionCalculationCriteria.BirthDate &&
    formDataToSubmit.ageRestrictionMaxDob
  ) {
    formDataToSubmit.ageRestrictionMaxDob =
      getDateFromDateAndTimeInputs({
        dateString: formDataToSubmit.ageRestrictionMaxDob as string,
        time: "00:00",
        timeZone: client.timeZone
      }) || null;
  }
  if (
    [
      AgeRestrictionCalculationCriteria.AgeOnSessionDate,
      AgeRestrictionCalculationCriteria.BirthDate
    ].includes(formDataToSubmit.ageRestrictionCriteria)
  ) {
    formDataToSubmit.ageRestrictionHandling =
      formDataToSubmit.ageRestrictionHandling || null;
  }

  return formDataToSubmit;
};
